//Libraries
import React, { FunctionComponent } from 'react'

//Componenets
import { PageWithLinksTypes } from '../z_components-types'
import PageWelcome from '../header/pageWelcome'
import TeamPicturesSection from '../free/teamPicturesSection'

const PageWithLinks: FunctionComponent<PageWithLinksTypes> = ({
  html,
  frontmatter,
  children,
}) => {
  return (
    <>
      <PageWelcome
        img={frontmatter.img}
        imgAltText="About Page Banner"
        title={frontmatter.title}
      />

      <article dangerouslySetInnerHTML={{ __html: html }}></article>

      <article>
        { frontmatter.team_pictures?.length > 0 &&
          <TeamPicturesSection team_pictures={frontmatter.team_pictures} />
        }
      </article>

      {children}

      {frontmatter.links[0] && (
        <article>
          <h2>Useful Links</h2>
          {frontmatter.links.map(
            (link: { link_name: string; link: string }, index: number) => (
              <div key={index}>
                <a href={link.link}>{link['link_name']}</a>
                <br />
              </div>
            )
          )}
        </article>
      )}
    </>
  )
}

export default PageWithLinks
