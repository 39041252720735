//Libraries
import React, { FunctionComponent } from 'react'
import { Link } from 'gatsby'
import cs from 'classnames'
import Img from 'gatsby-image'

//Types
import { PageWelcomeTypes } from '../z_components-types'

//Styles
import styles from './css/pageWelcome.module.scss'

//Components
import FontResize from './fontResize'

const PageWelcome: FunctionComponent<PageWelcomeTypes> = ({
  img,
  title,
  body,
  buttonLink,
  buttonText,
  imgAltText,
  announcement = false,
}) => {
  return (
    <div id="welcome" className={styles.welcome}>
      <div className={styles.pictureOverlay}></div>

      <Img
        fluid={img.childImageSharp.fluid}
        alt={imgAltText}
        className={styles.welcomeImage}
      />

      <aside
        className={cs(
          styles.welcomeBox,
          announcement && styles.announcement,
          !buttonText && styles.center
        )}
      >
        <h1 className={styles.welcomeText}>{title}</h1>
        {body && <p className={styles.welcomeBody}>{body}</p>}

        {buttonLink &&
          buttonText &&
          (buttonLink.startsWith('/') ? (
            <Link
              className={styles.welcomeButton}
              role="button"
              draggable={false}
              to={buttonLink}
            >
              <strong>{buttonText}</strong>
            </Link>
          ) : (
            <a
              className={styles.welcomeButton}
              role="button"
              draggable={false}
              href={buttonLink}
            >
              <strong>{buttonText}</strong>
            </a>
          ))}
      </aside>
      {!announcement && <FontResize />}
    </div>
  )
}

export default PageWelcome
