//Libraries
import React, { FunctionComponent } from 'react'
import Img from 'gatsby-image'

//Hooks
import { useImageQuery } from '../../hooks/useImageQuery'

//Types
import { ProfileCardTypes } from '../z_components-types'

//Styles
import styles from './css/profileCard.module.scss'

const ProfileCard: FunctionComponent<ProfileCardTypes> = ({
  img,
  title,
  description
}) => {
  //Checks if relative img path or vanilla gatsby image
  const images = typeof img === 'string' && useImageQuery()

  return (
    <div className={styles.profileCard}>
      <Img
        className={styles.cardImage}
        fluid={
          typeof img === 'string'
            ? images[img].childImageSharp.fluid
            : img.childImageSharp.fluid
        }
      />

      <h4 className={styles.cardTitle}>{title}</h4>

      <p className={styles.cardInfo}>{description}</p>
    </div>
  )
}

export default ProfileCard
