//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { PageWithLinksTypes } from './z_page-types'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import PageWithLinks from '../components/templates/pageWithLinks'
import PageBackground from '../components/free/pageBackground'

const EmergencyPreparedness: FunctionComponent<PageWithLinksTypes> = ({
  data,
}) => {
  return (
    <FontSizeProvider>
        <Helmet htmlAttributes={{lang: 'en'}}>
          <meta charSet="utf-8" />
          <title>OOA - Emergency Preparedness</title>
          <link
            rel="canonical"
            href="https://ooa.csi.edu/emergency-preparedness/"
          />
          <meta
            name="description"
            content="Emergency management planning integrates older adults and people with disabilities of all ages, and their caregivers, into community emergency planning, response, and recovery."
          />
        </Helmet>

      <Header />

      <Layout>
        <PageWithLinks
          html={data.markdownRemark.html}
          frontmatter={data.markdownRemark.frontmatter}
        />
      </Layout>

      <Footer />
      <PageBackground />
    </FontSizeProvider>
  )
}

export default EmergencyPreparedness

export const emergencyPreparednessQuery = graphql`
  query emergencyPreparedness {
    markdownRemark(
      frontmatter: { template_key: { eq: "emergency-preparedness-page" } }
    ) {
      html
      frontmatter {
        title
        links {
          link
          link_name
        }
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        team_pictures {
          name
          title
          img {
            childImageSharp {
              fluid(maxWidth: 1700, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
