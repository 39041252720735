//Libraries
import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from 'react'
import cs from 'classnames'

//Context
import { FontSizeContext } from '../../context/fontSizeContext'

//Styles
import styles from './css/fontResize.module.scss'

const FontResize: FunctionComponent<{}> = () => {
  const { fontSize, handleFontSize } = useContext(FontSizeContext)
  const sizeArr = ['small', 'regular', 'large']

  const [isScrollTop, setIsScrollTop] = useState(true)

  useEffect(() => {
    const checkScroll = () => {
      if (window.pageYOffset !== 0) {
        setIsScrollTop(false)
      } else {
        setIsScrollTop(true)
      }
    }

    window.addEventListener('scroll', checkScroll)

    return () => {
      window.removeEventListener('scroll', checkScroll)
    }
  }, [])

  useEffect(() => {
    sizeArr.forEach(
      item => {
        const itemElement = document.getElementById(item)
        if ( itemElement ) {
          itemElement.className = 'btn btn-secondary btn-lg btn-light'
        }
      }
    )
    const fontSizeElement = document.getElementById(fontSize)
    if ( fontSizeElement ) {
      fontSizeElement.classList.add(styles.active)
    }
  }, [fontSize])

  const HandleClick: (size: string) => void = size => {
    if (typeof handleFontSize === 'function') {
      handleFontSize(size)
    }
  }

  return (
    <aside
      role="group"
      className={cs(
        styles.fontResize,
        'btn-group',
        isScrollTop && styles.scrollTop
      )}
    >
      <span className={styles.fontResizeHeader}>
        <strong>Font Size</strong>
      </span>
      {sizeArr.map((size: string) => (
        <button
          key={size}
          id={size}
          type="button"
          className={styles[size]}
          onClick={() => HandleClick(size)}
        >
          {size[0].toUpperCase() + size.slice(1)}
        </button>
      ))}
    </aside>
  )
}

export default FontResize
