//Libraries
import React from 'react';

//Components
import ProfileCard from '../free/profileCard';

//Styles
import styles from './css/teamPicturesSection.module.scss';

//Types
import type { TeamPicturesSectionTypes } from '../z_components-types';
import type { FC } from 'react';

const TeamPicturesSection: FC<TeamPicturesSectionTypes> = ({ team_pictures }) => {
    if ( ( team_pictures?.length === 1 && team_pictures[0].name === 'Placeholder' ) || team_pictures?.length === 0 ) {
        return null;
    }

    return (
        <>
            <p>
                <strong>Meet the team</strong>
            </p>
            <div className={styles.teamPicturesSection}>
                { team_pictures.map( ( teamPicture, index ) => {
                    const { img, name, title } = teamPicture;

                    if ( name === 'Placeholder' ) {
                        return null;
                    }

                    return (
                        <ProfileCard key={index} img={img} title={name} description={title} />
                    );
                } ) }
            </div>
        </>
    );
};

export default TeamPicturesSection;