//Libraries
import React, { FunctionComponent } from 'react'
import Img from 'gatsby-image'

//Hooks
import { useImageQuery } from '../../hooks/useImageQuery'

//Styles
import styles from './css/page-background.module.scss'

const PageBackground: FunctionComponent<{}> = () => {
  const images = useImageQuery()
  return (
    <Img
      fluid={images.PageBackground.childImageSharp.fluid}
      alt={'background faded watermark'}
      className={styles.background}
      style={{ position: 'fixed'}}
    />
  )
}

export default PageBackground
